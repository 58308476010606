import React, { useState } from "react"
import LazyLoad from "react-lazyload"
import {
  Modal,
  Button,
  Icon,
  handleErrorWithPrismic,
  SENTRY_ACTIONS,
  Checkbox,
  pushTracking,
  EVENT,
  PARAMS,
} from "@lesmills/gatsby-theme-common"
import { API, graphqlOperation } from "aws-amplify"
import { AcceptChallenge, SwitchChallenge } from "../../graphql/challenges"
import type { LabelType } from "@lesmills/gatsby-theme-common"
import type {
  ChallengeType,
  ActiveChallengeType,
} from "../../../../types/ChallengesPageType"

import CustomDatePicker from "./CustomDatePicker"

type Props = {|
  handleDismiss: () => void | Promise<void>,
  challenge: ChallengeType,
  data: {
    accept_challenge_success_message: Array<LabelType>,
    accept_challenge_success_title: Array<LabelType>,
    accept_challenge_title: Array<LabelType>,
    subscribe_challenge_label: Array<LabelType>,
    subscribe_challenge_note: Array<LabelType>,
    start_date_label: Array<LabelType>,
    start_date_placeholder: Array<LabelType>,
    close_button_label: Array<LabelType>,
    lmod_challenges_accept_challenge_unhandled_error: Array<LabelType>,
    lmod_challenges_switch_challenge_unhandled_error: Array<LabelType>,
    sign_up_button_label: string,
  },
  email: string,
  areChallengeEmailsSubscribed: boolean,
  callback: () => void | Promise<void>,
  activeChallenge: ActiveChallengeType,
|}

const AcceptChallengeModal = ({
  challenge = {},
  data = {},
  email = "",
  handleDismiss = () => {},
  callback = () => {},
  activeChallenge,
}: Props) => {
  const [startDate, setStartDate] = useState(null)
  const [isProcessing, setProcessing] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState("")
  const [subscribed, setSubscribed] = useState(false)
  const {
    challenge_image = {
      alt: "",
      url: "",
      Mobile: {},
      Tablet: {},
    },
    challenge_name = { text: "" },
    challenge_duration = { text: "" },
    challenge_id,
    challenge_min_start_date,
    challenge_intensity = { text: "" },
  } = challenge
  const {
    accept_challenge_success_message = { text: "" },
    accept_challenge_success_title = { text: "" },
    accept_challenge_title = { text: "" },
    unsubscribe_challenge_note = { text: "" },
    subscribe_challenge_note = { text: "" },
    start_date_label = { text: "" },
    start_date_placeholder = {},
    close_button_label = {},
    cancel_calendar_button_label = {},
    apply_calendar_button_label = {},
    subscribe_challenge_label = {},
    sign_up_button_label,

    // Error
    lmod_challenges_accept_challenge_unhandled_error = {},
    lmod_challenges_switch_challenge_unhandled_error = {},
  } = data

  const handleClick = async () => {
    if (success) {
      handleDismiss()
    } else {
      setProcessing(true)

      try {
        let response
        const challengeId = challenge_id.text
        const startDateTimestamp = new Date(startDate).getTime() / 1000 // Timestamp in seconds

        if (activeChallenge) {
          // Switch challenge
          response = await API.graphql(
            graphqlOperation(SwitchChallenge, {
              newChallengeId: challengeId,
              startDate: startDateTimestamp,
            })
          )
        } else {
          // Accept challenge
          response = await API.graphql(
            graphqlOperation(AcceptChallenge, {
              challengeId,
              startDate: startDateTimestamp,
            })
          )
        }

        const { errors } = response
        const responseData = response.data || {}

        if (errors && errors.length > 0) {
          handleErrorWithPrismic(
            errors || [],
            activeChallenge
              ? lmod_challenges_accept_challenge_unhandled_error.text
              : lmod_challenges_switch_challenge_unhandled_error.text,
            ({ message }) => setError(message),
            data,
            activeChallenge
              ? SENTRY_ACTIONS.SWITCH_CHALLENGE
              : SENTRY_ACTIONS.ACCEPT_CHALLENGE
          )
          return
        }

        if (responseData.acceptChallenge || responseData.switchChallenge) {
          setSuccess(true)
          setError("")
          pushTracking({
            event: EVENT.CHALLENGE_START,
            [PARAMS.CHALLENGE_NAME]: challenge_name?.text,
            [PARAMS.CHALLENGE_LEVEL]: challenge_intensity?.text,
          })

          // Reset active challenge
          callback(challengeId, startDateTimestamp)
        }
      } catch (exceptionError) {
        handleErrorWithPrismic(
          exceptionError.errors || [],
          activeChallenge
            ? lmod_challenges_accept_challenge_unhandled_error.text
            : lmod_challenges_switch_challenge_unhandled_error.text,
          ({ message }) => setError(message),
          data,
          activeChallenge
            ? SENTRY_ACTIONS.SWITCH_CHALLENGE
            : SENTRY_ACTIONS.ACCEPT_CHALLENGE
        )
      } finally {
        setProcessing(false)
      }
    }
  }

  const getModalTitle = () => {
    // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/ENG-573
    // Without active challenge, we will show accept challenge content
    // With active challenge, we will show switch challenge content
    return (success
      ? accept_challenge_success_title
      : accept_challenge_title
    ).text.replace("@challenge_name", challenge_name.text)
  }

  const getModalDescription = () => {
    return (success
      ? accept_challenge_success_message
      : start_date_label
    ).text.replace("@challenge_duration", challenge_duration.text.toLowerCase())
  }
  return (
    <Modal
      handleDismiss={handleDismiss}
      classNames={{
        content:
          " challenge-popup w-auto md:w-challenge-popup-md sm:w-challenge-popup sm:h-challenge-popup-sm overflow-auto md:py-0 md:px-0",
      }}
      testId="accept-challenge-modal"
    >
      <div
        className={`challenge-popup-content md:h-full flex justify-between items-center my-0 mx-0`}
      >
        {/* Thumbnail */}
        <LazyLoad height="h-challenge-popup-md">
          <img
            alt={challenge_image.alt}
            src={challenge_image.url}
            className="hidden md:h-full md:block object-cover w-challenge-image"
            data-cy="challenge-image"
            data-testid="challenge-image"
          />
        </LazyLoad>

        {/* Content */}
        <div className="challenge-popup-container flex flex-col justify-center w-full px-24 pt-80 md:pt-0">
          {success && (
            <div className="mx-auto rounded-full mb-25 flex justify-center items-center w-challenge-success-icon h-challenge-success-icon bg-green-600">
              <Icon icon="check-mark" type="base" />
            </div>
          )}
          <h5
            className={`text-6xl md:text-9xl leading-32 font-primary text-gray-800 uppercase ${
              success ? "text-center" : ""
            }`}
          >
            {getModalTitle()}
          </h5>
          <p
            className={`text-gray-800 font-base-light text-base leading-24 my-25 ${
              success ? "text-center" : ""
            }`}
          >
            {getModalDescription()}
          </p>
          {!success && (
            <>
              <CustomDatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                placeholder={start_date_placeholder.text}
                cancelButtonLabel={cancel_calendar_button_label.text}
                applyButtonLabel={apply_calendar_button_label.text}
                minDate={
                  Date.parse(challenge_min_start_date) > new Date()
                    ? Date.parse(challenge_min_start_date)
                    : new Date()
                }
              />
              <Checkbox
                label={subscribe_challenge_label.text}
                styles={{
                  wrapper: "cb-wrapper-base font-base-light leading-normal",
                  checkmark: "checkmark-base",
                }}
                disabled={isProcessing}
                handleOnChange={() => setSubscribed(!subscribed)}
                checked={subscribed}
              />
            </>
          )}

          <Button
            handleOnClick={handleClick}
            disabled={!startDate || isProcessing || !subscribed}
            submitting={isProcessing}
            className={`btn-get-package btn btn-primary-gray pt-3 pb-3 mb-15 mt-25 w-full`}
            testId="get-package-btn"
          >
            {success ? close_button_label.text : sign_up_button_label}
          </Button>

          {!success && (
            <p className="text-gray-500 font-base-light md:text-3xs text-xs leading-20 text-center">
              {(subscribed
                ? subscribe_challenge_note
                : unsubscribe_challenge_note
              ).text.replace("@user_email", email)}
            </p>
          )}
          {error && (
            <p className="text-red-600 font-base-light text-base leading-24 mt-25">
              {error}
            </p>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default AcceptChallengeModal
