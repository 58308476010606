import React, { useRef, useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Textbox, Icon, Button, isBrowser } from "@lesmills/gatsby-theme-common"
type DatePickerInputProps = {|
  value: string,
  onClick: () => void,
|}

type CustomDatePickerProps = {|
  selected: Date,
  onChange: () => void,
  wrapperRef: () => void,
  placeholder: string,
  cancelButtonLabel: string,
  applyButtonLabel: string,
  minDate: Date,
|}

const CustomDatePicker = ({
  selected,
  onChange = () => {},
  placeholder = "",
  minDate,
  cancelButtonLabel = "Cancel",
  applyButtonLabel = "Apply",
}: CustomDatePickerProps) => {
  const calendarRef = useRef({})
  let inputRef
  const [calendarData, setCalendarData] = useState({
    isOpening: false,
    selectDate: selected,
  })
  // This will help to distinguish desktop and mobile screen
  const isMobile = isBrowser && window.innerWidth < 768

  const DatePickerInput = ({ value, onClick }: DatePickerInputProps) => (
    <div className="w-full" onClick={onClick}>
      <Textbox
        label={placeholder}
        classNames={{
          textbox:
            "w-full h-textbox-base md:h-textbox-lg text-base md:text-2lg",
          label: "md:text-2lg",
          wrapper: "w-full border-gray-500",
        }}
        defaultValue={value}
        error={""}
        testId="start-date"
        id="startDate"
        disabled={false}
        icon={
          <Icon
            icon="date-picker"
            type="base"
            classNames="date-picker-custom-icon absolute right-15 top-1/2 cursor-pointer"
          />
        }
      />
    </div>
  )

  const CalendarFooter = () => {
    const btnClass =
      "btn capitalize underline text-xs font-base-light text-gray-800"

    return (
      <div className="custom-popper-footer sm:hidden flex justify-between items-center">
        <Button
          handleOnClick={() => inputRef.setOpen(false)}
          className={btnClass}
          testId="cancel-calendar-btn"
        >
          {cancelButtonLabel}
        </Button>
        <Button
          handleOnClick={() => {
            inputRef.setOpen(false)
            onChange(calendarData.selectDate)
          }}
          className={btnClass}
          testId="apply-calendar-btn"
        >
          {applyButtonLabel}
        </Button>
      </div>
    )
  }

  return (
    <DatePicker
      ref={element => (inputRef = element)}
      onCalendarOpen={() =>
        setCalendarData({ ...calendarData, isOpening: true })
      }
      onCalendarClose={() =>
        setCalendarData({ ...calendarData, isOpening: false })
      }
      selected={selected}
      onClickOutside={() => onChange(calendarData.selectDate)}
      onSelect={(date, event) => {
        if (event.target.type === "button" && isMobile) {
          return
        }

        // On desktop, tablet still allows to change value once selecting
        if (!isMobile) {
          onChange(date)
        }

        setCalendarData({ ...calendarData, selectDate: date })
      }}
      customInput={<DatePickerInput />}
      customInputRef={calendarRef}
      popperClassName="custom-popper-picker"
      popperContainer={({ children }) => (
        <div
          className={`${calendarData.isOpening ? "custom-popper-wrapper" : ""}`}
        >
          <div
            className={`${
              calendarData.isOpening ? "custom-popper-content" : ""
            }`}
          >
            {children}
          </div>
        </div>
      )}
      popperPlacement="center-start"
      todayButton={<CalendarFooter />}
      minDate={minDate}
      formatWeekDay={formattedDate => formattedDate.substr(0, 1)}
      shouldCloseOnSelect={!isMobile} // Only close on select for desktop, tablet
    />
  )
}

export default CustomDatePicker
